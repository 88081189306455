import "./technique-prices.scss";

import * as React from "react";
import { PropsWithChildren } from "react";

import { Rate } from "../../../contexts/shared/domain/Prices";

interface PropsTechniquePrices extends PropsWithChildren<any> {
  rates: Rate[];
}

const TechniquePrices: React.FC<PropsTechniquePrices> = (
  props: PropsTechniquePrices
) => {
  // const { rates } = props;

  const rates_render = [];

  function compare(rateA: Rate, rateB: Rate) {
    if (rateA.is_technique) {
      return rateB.priceSession - rateA.priceSession;
    }
    return 0;
  }

  const rates = props.rates.sort(compare);

  rates.forEach((rate: Rate, index) => {
    rates_render.push(
      <div className={"rate"} key={index}>
        <p className={"h3 text-center"}>{rate.name}</p>
        <div
          className={
            "m-card m-card--grey-blue m-card--no-shadow grid-70-30 grid-70-30--break-pro grid-70-30--no-gap-desktop"
          }
        >
          <div className={"m-card__discount"}>
            <p className={"a-card__paragraph"}>
              { rate.link === "ecografia" ? <> </> : <>50 miutos</> }
            </p>
          </div>
          <div className={"is-text-center"}>
            <p
              className={
                "a-card__paragraph a-card__paragraph-number is-fs-title-xl"
              }
            >
              {rate.priceSession}
            </p>
            <p className={"a-card__paragraph"}>€ / sesión</p>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className={"o-technique-prices container rates__sections"}>
      <p className={"h3 text-center"}>
        <span className={"is-grey"}>Tarifas </span> Fisioterapía
      </p>
      <div className="grid-x2 ">{rates_render}</div>
      <div className={"m-cta"}>
        <h2 className={"a-cta__title"}>Cuida de tu salud con Obelion</h2>
        <a
          href={
            "https://www.treebole-clinicas.net/es/customer/requestappo/cusrc/g84fzxc8vvaguap"
          }
          target={"_blank"}
          rel={"noopener noreferrer nofollow"}
          className={"a-button"}
        >
          Pide cita
        </a>
      </div>
    </div>
  );
};

export default TechniquePrices;
